.catalog-product-view {
    h1 {
        font-size: 2rem;
    }

    .product-info-list-item {
        white-space: nowrap;

        &.product-info-storagetype {
            img {
                max-width: 11px;
                transform: translateY(-2px);
                margin-right: 6px;
            }
        }
    }

    .product-info-list-item:after {
        content: ' | ';
        white-space: normal;
    }

    .product-info-list-item:last-child:after {
        content: '';
    }
}
